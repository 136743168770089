import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import Img from "gatsby-image";
import SEO from "../components/seo";

class PageTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const frontmatter = post.frontmatter;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const image = frontmatter.image;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        {image && <Img sizes={image.childImageSharp.sizes} />}

        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <hr />
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`;
