import { Link } from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import React from "react";

const Header = ({ siteTitle, pages, logo }) => (
  <header>
    <h1>
      <Link
        to="/"
        style={{
          textDecoration: `none`
        }}
      >
        {logo ? (
          <Img
            className="logo"
            fluid={logo.childImageSharp.fluid}
            alt={siteTitle}
          />
        ) : (
          siteTitle
        )}
      </Link>
    </h1>
    <nav>
      {pages.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <Link
            key={node.fields.slug}
            style={{ boxShadow: `none`, marginRight: "10px" }}
            to={node.fields.slug}
          >
            {title}
          </Link>
        );
      })}
    </nav>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
