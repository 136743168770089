/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { filterPagesByType } from "../utils";

import Header from "./header";

const underscore = str => str.toLowerCase().replace(" ", "_");

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          menu
        }
      }
      logo: file(name: { eq: "logo" }) {
        name
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            fileAbsolutePath
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  const menu = data.site.siteMetadata.menu.map(underscore);

  const pages = filterPagesByType(data.allMarkdownRemark.edges, "pages")
    .filter(page => menu.includes(underscore(page.node.frontmatter.title)))
    .sort(function(a, b) {
      const aTitle = underscore(a.node.frontmatter.title);
      const bTitle = underscore(b.node.frontmatter.title);

      return menu.indexOf(aTitle) - menu.indexOf(bTitle);
    });

  return (
    <>
      <Header
        logo={data.logo}
        siteTitle={data.site.siteMetadata.title}
        pages={pages}
      />
      <div>
        <main>{children}</main>
        <footer>© {new Date().getFullYear()}</footer>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
